<script lang="ts">
	import Text from '$lib/components/input/text.svelte';
	import { slide } from 'svelte/transition';

	interface Props {
		name: string;
		label: string;
		type?: string;
		error: string[] | undefined;
		value: string | undefined;
		disabled?: boolean;
	}

	let {
		name,
		label,
		type = 'text',
		// Expects $errors
		error = $bindable(undefined),
		// Expects $form
		value = $bindable(''),
		disabled = false
	}: Props = $props();
</script>

<div class="input-container">
	<span class="text-input">
		<Text
			label_for={name}
			{label}
			{type}
			{name}
			{disabled}
			error={error !== undefined}
			bind:value
			$$restProps
		/>
	</span>
	<div>
		{#if error}
			<div class="validation-message" transition:slide|local={{ duration: 300, axis: 'y' }}>
				<ul class="validation-message-ul">
					{#each error as message (message)}
						<li transition:slide|local={{ duration: 300, axis: 'y' }}>
							{message}
						</li>
					{/each}
				</ul>
			</div>
		{/if}
	</div>
</div>

<style>
	.text-input {
		position: relative;
		z-index: 1;
	}

	.input-container {
		position: relative;
		width: 100%;
	}

	.validation-message {
		position: relative;
		width: 100%;
		background-color: rgb(24 24 27);
		border-color: rgb(98, 98, 99);
		border-radius: 0 0 0.375rem 0.375rem;
		border-style: solid;
		border-width: 2px;
		border-top: none;
		padding: 0.3rem;
		box-sizing: border-box;
		margin-top: -6px;
		z-index: 0;
	}

	.validation-message-ul {
		margin: 0;
		padding: 0;
		padding-top: 0.25rem;
		list-style: none;
		color: #f07575;
	}

	.validation-message-ul li {
		margin-bottom: 0.25rem;
	}

	.validation-message-ul li:last-child {
		margin-bottom: 0;
	}
</style>
