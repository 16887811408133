<script lang="ts">
	import { createBubbler, preventDefault, handlers } from 'svelte/legacy';

	const bubble = createBubbler();

	interface Props {
		functionToRun?: (event: MouseEvent) => void;
		style?: 'link' | 'default' | 'icon';
		children?: import('svelte').Snippet;
		[key: string]: any
	}

	let { functionToRun = () => {}, style = 'link', children, ...rest }: Props = $props();
</script>

<button onclick={handlers(preventDefault(bubble('click')), functionToRun)} class={style} {...rest}>
	{@render children?.()}
</button>

<style>
	.link {
		padding: 0.25rem;
		border-radius: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		font-weight: 600;
		background-color: transparent;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 300ms;
		transition-duration: 150ms;

		color: rgb(251 146 60);

		@media (min-width: 1280px) {
			font-size: 1rem;
			line-height: 1.5rem;
		}

		&:hover {
			text-decoration: none;
			background-color: rgb(234 88 12 / 0.3);
		}
	}

	.icon {
		padding: 0.4rem;
		border-radius: 0.375rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 300ms;
		transition-duration: 150ms;
		width: 30px; /* Initial size */
		height: 30px; /* Initial size */
		@media (min-width: 1280px) {
			font-size: 1rem;
			line-height: 1.5rem;
		}

		&:hover {
			background-color: rgba(128, 128, 128, 0.2);
		}
	}
</style>
